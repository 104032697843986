import './demo.scss'

const HomeDemo = (): JSX.Element => {
  return (
    <>
      <div className="nav_box">
        <div className="nav_top">
          <div className="wrap">
            <ul className="link_w">
              <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">Collection 4399</a></li>
              <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" >Save to your desktop</a></li>
            </ul>
            {/* <div className="search">
              <form style={{ display: 'inline' }} method="get" action="//so2.4399.com/search/search.php">
                <div className="s_fl">
                  <input autoComplete="off" name="k" value="搜索小游戏" className="sh text" id="smart_input" />
                </div>
                <div className="s_fl">
                  <input type="submit" value="" className="s_btn" />
                </div>
              </form>
            </div> */}
            <div className="link_r" id="func">
              <a target={'_blank'} rel="noreferrer" className="ico_1" id="login_tologin" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">login</a>
              <a target={'_blank'} rel="noreferrer" id="login_toregister" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">register</a>

              <a target={'_blank'} rel="noreferrer" className="ico_6" style={{ display: 'none' }} href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" >I've played</a>
              <a target={'_blank'} rel="noreferrer" className="ico_2" style={{ display: 'none' }} href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" >you may also like</a>
              <a target={'_blank'} rel="noreferrer" className="ico_3" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" id="chskin"><em className="s_em">Peel</em></a>
            </div>
            <a target={'_blank'} rel="noreferrer" className="i6_hover" id="showplay1" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" style={{ display: 'none' }}>�������</a>
            <div className="played p_ml" id="showplay2" style={{ display: 'none' }}>
              <div className="top-tit cf">
                <i></i>
                <a target={'_blank'} rel="noreferrer" className="a" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">�ҵ��ղ�</a>
                <a target={'_blank'} rel="noreferrer" className="dl" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">Peel</a>
              </div>
              <div className="not_p1" id="reading3">
                ���ݶ�ȡ�У����Ժ�...
              </div>
              <div className="not_p" id="showword3" style={{ display: 'none' }}>
                ����&ucirc;���ղع��κ���Ϸ
              </div>
              <div className="not_p1" id="retry3" style={{ display: 'none' }}>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" >����ʱ��������</a>
              </div>
              <div id="sclist" className="d-line" style={{ display: 'none' }}></div>
              <div className="top-tit cf">
                <i></i>
                <a target={'_blank'} rel="noreferrer" className="a" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">������</a>
                <a target={'_blank'} rel="noreferrer" className="dl" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">Peel</a>
              </div>
              <div className="not_p1" id="reading1" style={{ display: 'none' }}>
                ���ݶ�ȡ�У����Ժ�...
              </div>
              <div className="not_p1" id="showword" style={{ display: 'none' }}>
                ��ʱ��&ucirc;����Ϸ��&frac14;�������������Ϸ��
              </div>
              <div id="tjgameplay"></div>
              <div id="rel_pagplay"></div>
            </div>
            <a target={'_blank'} rel="noreferrer" className="i2_hover" id="showc1" style={{ display: 'none' }} href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" >����ϲ��</a>
            <div className="login" id="logined2" style={{ display: 'none' }} ></div>
            <div className="played" id="showc2" style={{ display: 'none' }} >
              <div className="top-tit cf">
                <i></i>
                <a target={'_blank'} rel="noreferrer" className="morediv" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">����ϲ��</a>
                <a target={'_blank'} rel="noreferrer" className="morediv" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">����</a>
              </div>
              <div className="not_p1" id="notj">
                ����������Ϸ��&frac14;��Ϊ���Ƽ�������Ϸ
              </div>
              <div className="not_p1" id="reading2" style={{ display: 'none' }}>
                ���ݶ�ȡ�У����Ժ�...
              </div>
              <div id="tjgame"></div>
              <div id="rel_pag"></div>
            </div>
          </div>
        </div>
      </div>
      {/* top */}
      {/* <div className="top">
        <a target={'_blank'} rel="noreferrer"  className="logo" ><img alt="4399 Games" src={require('../../assets/img/logo.png').default} /></a>
        <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="tdc"><img src={require('../../assets/images/4399_16561781227.gif').default} /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 game box</span></span></a>
        <div className="reco">
          <div className="reco_c" id="web_logo_1">
            <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img alt="Dantantang" src={require('../../assets/images/4399_16365112485.jpg').default} /></a>
            <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img alt="Pika Hall" src={require('../../assets/images/4399_14544051281.jpg').default} /></a>
            <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img alt="Hot-blooded Ming Dynasty" src={require('../../assets/images/4399_10245723554.jpg').default} /></a>
            <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img alt="FireWire Elite" src={require('../../assets/images/4399_16574278974.jpg').default} /></a>
            <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img alt="viking legend" src={require('../../assets/images/4399_16200883179.jpg').default} /></a>
          </div>

        </div>
        <div className="reco_r">
          <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" rel="nofollow" ><img alt="4399 web games" src={require('../../assets/images/4399_14030376672.jpg').default} /></a>
          <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" ><img alt="Above All Things: Awakening" src={require('../../assets/images/4399_15351117999.jpg').default} /></a>
        </div>
      </div> */}
      {/* nav */}
      <div className="menu">
        <div className="menu_le">
          <a target={'_blank'} rel="noreferrer" className="m_on gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>front page</span></span></a>
          {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>action</span></span></a> */}
          {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>physical education</span></span></a> */}
          {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>puzzle</span></span></a> */}
          {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>shooting</span></span></a>
          <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>adventure</span></span></a> */}
          {/* <a target={'_blank'} rel="noreferrer"  className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>chess and cards</span></span></a> */}
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Strategy</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Leisure</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>girl</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>dress up</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>child</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>pass</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Double</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>cartoon</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Shanyi Interactive</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="gp" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>mobile game</span></span></a>
        </div>
        <div className="menu_ri">
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Web games</span></span></a>
          {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Game Bar</span></span></a> */}
        </div>
      </div>
      {/* game1 */}
      <div id="ads1_1" style={{ clear: 'both', width: '980px', padding: '0px', margin: '0px auto', display: 'block' }}>
        {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img alt="蛋仔派对套装、蛋币免费送" src={require('../../assets/images/4399_07495683364.png').default} /></a> */}
      </div>
      <div className="middle_1">
        {/* <!--class="mi_2"--> */}
        <a target={'_blank'} rel="noreferrer" className="for_phone" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>mobile games</span></span></a>
        <ul className="mi_ul">
          <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><em><img alt='' src="https://imga3.5054399.com/upload_pic/2023/9/23/4399_11265185298.jpg" /></em><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>truck life</span></span><i className="h"></i></a></li>
          <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" ><em><img alt='' src="https://imga2.5054399.com/upload_pic/2023/9/19/4399_17180816073.jpg" /></em><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>High energy hero</span></span></a></li>
          <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" ><em><img alt='' src="https://imga2.5054399.com/upload_pic/2023/9/19/4399_17175835807.jpg" /></em><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Light gap explanation</span></span></a></li>
          <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><em><img alt='' src="https://imga3.5054399.com/upload_pic/2023/9/14/4399_17502362839.jpg" /></em><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Holy Tower</span></span></a></li>
        </ul>
        <div className="idesk" style={{ width: 'auto' }}>
          {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="i_a1"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Android mobile games collection</span></span></a> */}
          {/* <!-- <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="i_a2">赚零钱赢礼品</a> --> */}
        </div>
      </div>
      <div className="middle_2">
        <div className="mi_d1">
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>album</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Cloud gaming</span></span></b></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Hot travel recommendations</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Latest games</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Two-player mini-game</span></span></b></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Invincible version</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>individual</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Ice and Fire Man</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>three people</span></span></a></span> */}
            </div>
          </div>
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>child</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>fill color</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>my little pony</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>spongebob</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Assembly game</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Dora</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>study</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Toy</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>thomas</span></span></a></span> */}
            </div>
          </div>
        </div>
        <div className="mi_d2">
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>action</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>fighting</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Lego</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>boys games</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Three Kingdoms</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>effort</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Transformers</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Matchstick Men</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>journey to the west</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>robot</span></span></a></span> */}
            </div>
          </div>
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>shooting</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Cross the line of defense</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>sniper</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>counter strike</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>cannon</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>fighter</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>special forces</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>tank</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Shootout SWAT</span></span></a></span> */}
            </div>
          </div>
        </div>
        <div className="mi_d1">
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>puzzle</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Lianliankan</span></span></b></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Solve puzzles</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>puzzle</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>maze</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Monopoly</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Looking for trouble</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Tower Defense</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Looking for something</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Mahjong</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Small train</span></span></a></span> */}
            </div>
          </div>
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Leisure</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Landlords</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Zuma</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>gold miner</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Xiaoxiankan</span></span></a></span> */}
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Pac Doudou</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Bubble Bobble</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>helicopter</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>farm</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>eat</span></span></a></span> */}
            </div>
          </div>
        </div>
        <div className="mi_d2">
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>physical education</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>racing</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>go kart</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>SUV</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>bike</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>car</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>parking</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>basketball</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>football</span></span></b></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>roller coaster</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>billiards</span></span></a></span> */}
            </div>
          </div>
          <div className="mi-lr">
            <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>adventure</span></span></a>
            <div className="mi_d">
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Chinese selection</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Big fish eats small fish</span></span></a></span>
              <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>anime games</span></span></a></span>
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Adventure Island</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>ninja</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Parkour</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>warrior</span></span></a></span> */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>break through</span></span></a></span>/ */}
              {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>3D games</span></span></a></span> */}
            </div>
          </div>
        </div>
        <div className="mi_d1 mi_block">
          <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>girl</span></span></a>
          <div className="mi_g">
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>asue</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>beautiful pictures</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Mermaid</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Princess</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Ancient costume change</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Cook</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Elf</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>make up</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Manicure</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Wedding dress</span></span></a></span>

          </div>
        </div>
        <div className="mi_d2 mi_block">
          <a target={'_blank'} rel="noreferrer" className="mi_tit" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>compilation</span></span></a>
          <div className="mi_g">
            <div className="caro" id="s1">
              <ul>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 Apocalypse of Ice and Fire: Smooth battles and dazzling transformations</span></span></a></li>
                {/* <li><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 The generals fought fiercely in Shacheng, with beams of light everywhere</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 I am the general and a magical soldier, traveling through the Three Kingdoms</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 The road to becoming a god in ancient legends is full of ups and downs</span></span></a></li> */}
              </ul>
            </div>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Hundred Battles Sand City</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>etheric light</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Legendary Overlord</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>The Legend of White Snake</span></span></a></span>
            {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Ozzie legend</span></span></b></a></span> */}
            {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>kingdom of gods</span></span></a></span> */}
            {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>The Legend of the Three Kingdoms</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Pika Hall</span></span></b></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>sky dance</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>life and death sniper 2</span></span></b></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>general</span></span></a></span> */}
          </div>
        </div>
        <div className="mi_d3 mi_block">
          <a target={'_blank'} rel="noreferrer" className="more" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>More&gt;&gt;</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="game_w" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Web games</span></span></a>
          <div className="mi_web">
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>play a ball</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Three Kingdoms Quick Fight</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Dantantang</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>life and death sniper</span></span></b></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>viking legend</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Touch touch</span></span></a></span>
            {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Food vs. Rats</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><b><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>FireWire Elite</span></span></b></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Light of Oya</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>little flower fairy</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Aola star</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Gun Soul</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Three Kingdoms Killing</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Mortal Cultivation 2</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Fairyland</span></span></a></span> */}
          </div>
        </div>
        <div className="mi_d2 mi_block">
          <a target={'_blank'} rel="noreferrer" className="more" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>More&gt;&gt;</span></span></a>
          <a target={'_blank'} rel="noreferrer" className="h5_w" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>H5 games</span></span></a>
          <div className="mi_g">
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Hit the bullseye</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>zero front</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Codename Zhan</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>angry rabbit</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Agent mission</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>A hero comes to take charge</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Bugs Wars</span></span></a></span>
            {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Little richest man</span></span></a></span> */}
            {/* <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>New Legend of Heaven and Dragon Slaying</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Mortal God General</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Three Kingdoms H5</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Super Poke Ball</span></span></a></span>
            <span><a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Defeating the Three Kingdoms</span></span></a></span> */}
          </div>
        </div>
      </div>
      <div className="p-forum">
        {/* <a target={'_blank'} rel="noreferrer"  className="p-ico" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"></a> */}
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Her Majesty the Queen </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Eileen's Psalms </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Happy Three Kingdoms Killing </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Three Kingdoms </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Heroes Heart of the King 2 </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Great General </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Little Elf </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="f38"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>National Academic Master </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Apocalypse Ice and Fire Apocalypse </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Little Commando </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>300 Heroes </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fb6"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Soul Blade </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Peak Tank </span></span></a>
        <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Collapse 3</span></span></a>
      </div>
      <div className="rollgame">
        <div className="game_box" id="gamesdiv" style={{ width: '936px', overflow: 'hidden' }}>
          <div style={{ overflow: 'hidden', zoom: 1, width: '32766px', height: '144px' }}>
            <ul className="ulleft">
              <ul className="g_list">
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2014/5/15/4399_17081743871.jpg" alt="WWII Battlefront 2 Invincible Edition" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>WWII Battlefront 2 Invincible Edition</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2016/9/14/4399_15081493016.jpg" alt="racing" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>racing</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2017/5/11/4399_10520731217.jpg" alt="Plants vs. Zombies" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Plants vs. Zombies</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2023/9/21/4399_15582990362.jpg" alt="Creation and magic" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Creation and magic</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga4.5054399.com/upload_pic/2023/4/18/4399_14180992062.jpg" alt="4399 Three Kingdoms Kill" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 Three Kingdoms Kill</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2023/9/21/4399_17413172537.gif" alt="life and death sniper" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>life and death sniper</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2021/12/30/4399_14172193097.jpg" alt="Light of Oya" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Light of Oya</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga1.5054399.com/upload_pic/2023/8/4/4399_16090183740.jpg" alt="Xipu continent" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Xipu continent</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2023/4/28/4399_11573899392.jpg" alt="4399 Aola Star" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 Aola Star</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2023/4/18/4399_14182026352.jpg" alt="4399 Obi Island" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 Obi Island</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2023/3/23/4399_18470005607.jpg" alt="Food vs. Rats" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Food vs. Rats</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2022/7/6/4399_10303289537.jpg" alt="4399 Purcell No." /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 Purcell No.</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga4.5054399.com/upload_pic/2016/8/3/4399_17071810028.jpg" alt="The latest collection of mini games" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>The latest collection of mini games</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga4.5054399.com/upload_pic/2018/3/5/4399_15464863079.jpg" alt="work to earn money" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>work to earn money</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2016/5/11/4399_14110351806.jpg" alt="Assemble" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Assemble</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga1.5054399.com/upload_pic/2018/3/13/4399_15535036594.jpg" alt="Cook" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Cook</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga2.5054399.com/upload_pic/2016/9/7/4399_08003428970.jpg" alt="Lego" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Lego</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2014/5/15/4399_17205402847.jpg" alt="Parkour" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Parkour</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga2.5054399.com/upload_pic/2021/2/24/4399_08570378043.jpg" alt="4399 play online" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 play online</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2021/11/9/4399_15324458100.jpg" alt="car" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>car</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga1.5054399.com/upload_pic/2021/12/7/4399_17200203636.jpg" alt="tank" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>tank</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga1.5054399.com/upload_pic/2023/7/24/4399_16580814540.jpg" alt="FireWire Elite" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>FireWire Elite</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga2.5054399.com/upload_pic/2017/2/25/4399_16171029957.jpg" alt="billiards" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>billiards</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2021/11/30/4399_09194770386.jpg" alt="SUV" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>SUV</span></span></a></li>
              </ul>
              <ul className="g_list">
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2019/2/2/4399_11351875862.jpg" alt="Invincible version" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Invincible version</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga4.5054399.com/upload_pic/2023/3/20/4399_10465322102.jpg" alt="little flower fairy" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>little flower fairy</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga1.5054399.com/upload_pic/2022/12/6/4399_14391919711.jpg" alt="Genshin Impact" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Genshin Impact</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga2.5054399.com/upload_pic/2022/1/28/4399_10171295850.jpg" alt="my world" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>my world</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2023/8/29/4399_16211842866.jpg" alt="Dantantang" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Dantantang</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga2.5054399.com/upload_pic/2023/8/2/4399_15265714328.jpg" alt="Run away!  juvenile" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Run away! </span><span style={{ verticalAlign: 'inherit' }}>juvenile</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2023/8/18/4399_11523698744.jpg" alt="4399 Mini World" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 Mini World</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2022/5/9/4399_11570165433.jpg" alt="Douluo Dalu" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Douluo Dalu</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2023/7/17/4399_14551367446.jpg" alt="Pika Hall Playing House" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Pika Hall Playing House</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga4.5054399.com/upload_pic/2023/6/29/4399_09372983266.jpg" alt="Classic game cloud play" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Classic game cloud play</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2023/9/13/4399_08450579251.jpg" alt="4399 Legend of Ochi" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>4399 Legend of Ochi</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2021/12/14/4399_16090221977.jpg" alt="Heroes of the Three Kingdoms" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Heroes of the Three Kingdoms</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2016/10/9/4399_13402210098.jpg" alt="tower defense game" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>tower defense game</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga.5054399.com/upload_pic/2016/7/29/4399_14303296764.jpg" alt="Girls Games Selection" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Girls Games Selection</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2014/5/15/4399_17551661404.jpg" alt="individual" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>individual</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga2.5054399.com/upload_pic/2021/7/13/4399_15523010860.jpg" alt="Honkai Impact 3" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Honkai Impact 3</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga3.5054399.com/upload_pic/2018/9/28/4399_15042504999.jpg" alt="3D games" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>3D games</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2018/12/7/4399_10110779970.jpg" alt="fighting" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>fighting</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2017/9/6/4399_08405919324.jpg" alt="Kabu's Journey to the West" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Kabu's Journey to the West</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga1.5054399.com/upload_pic/2016/8/3/4399_17073666938.jpg" alt="Selected Games for Boys" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Selected Games for Boys</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga2.5054399.com/upload_pic/2022/11/4/4399_16272843493.jpg" alt="Cloud gaming" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Cloud gaming</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga5.5054399.com/upload_pic/2014/5/15/4399_17502294860.jpg" alt="Locke Kingdom" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Locke Kingdom</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga4.5054399.com/upload_pic/2017/1/14/4399_15424675974.jpg" alt="Matchstick Men" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Matchstick Men</span></span></a></li>
                <li><a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"><img src="https://imga1.5054399.com/upload_pic/2017/10/20/4399_10035447416.jpg" alt="Dragon Fighter" /><span style={{ verticalAlign: 'inherit' }}><span style={{ verticalAlign: 'inherit' }}>Dragon Fighter</span></span></a></li>
              </ul>
            </ul>

          </div>
        </div>
      </div>
      <div className="middle_3 cf">
        <div className="box_c">
          <div className="tm_fun h_3 ">
            <div className="tm_tit">
              <span className="tit_ico1" />
              <a target={'_blank'} rel="noreferrer" className="tit_a" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                List of the latest fun games
              </a>
              {/* <a target={'_blank'} rel="noreferrer"  className="more_1" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" /> */}
              {/* <div className="tm_upd">
                <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <span className="new_ico" />
                  24
                  mini &nbsp;-

                  games have been updated today&nbsp;
                </a>
                 &nbsp;|&nbsp; 
                <a target={'_blank'} rel="noreferrer" 
                  target="_self"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  
                      Latest recommended games
                 
                </a>
                 &nbsp;|&nbsp;
              </div> */}
            </div>
            <ul className="tm_list ">
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <span className="new" />
                  <img
                    alt="crazy escapee"
                    src="//imga4.5054399.com/upload_pic/2023/3/23/4399_16412252247.jpg"
                  />
                  crazy escapee
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <span className="new" />
                  <img
                    alt="Return all evil"
                    src="//imga2.5054399.com/upload_pic/2023/8/10/4399_15033216957.jpg"
                  />
                  Return all evil
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"

                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="life and death sniper 2"
                    src="//imga3.5054399.com/upload_pic/2023/8/16/4399_14301962809.jpg"
                  />
                  life and death sniper 2
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Animal chicken shootout"
                    src="//imga3.5054399.com/upload_pic/2023/8/24/4399_17154690054.jpg"
                  />
                  Animal chicken shootout
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="4399 FireWire Elite"
                    src="//imga5.5054399.com/upload_pic/2023/7/26/4399_14423338193.jpg"
                  />
                  4399 FireWire Elite
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="blaster assault"
                    src="//imga2.5054399.com/upload_pic/2023/9/20/4399_11334750817.jpg"
                  />
                  blaster assault
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Dream Journey to the West online"
                    src="//imga2.5054399.com/upload_pic/2021/5/31/4399_20182326706.jpg"
                  />
                  Dream Journey to the West online

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <span className="hot" />
                  <img
                    alt="Unparalleled dream creation"
                    src="//imga1.5054399.com/upload_pic/2023/9/13/4399_17221315590.jpg"
                  />

                  Unparalleled dream creation

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Qingluan Prosperity Record"
                    src="//imga5.5054399.com/upload_pic/2023/9/7/4399_11414055372.jpg"
                  />

                  Qingluan Prosperity Record

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="I was a princess in ancient times 2"
                    src="//imga4.5054399.com/upload_pic/2023/6/9/4399_18323163627.jpg"
                  />

                  I was a princess in ancient times 2

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Puzzle machine maze"
                    src="//imga1.5054399.com/upload_pic/2023/7/22/4399_11102325231.jpg"
                  />

                  Puzzle machine maze

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Bleach vs Naruto 3.3"
                    src="//imga2.5054399.com/upload_pic/2019/4/23/4399_09595943507.jpg"
                  />

                  Bleach vs Naruto 3.3

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Genshin Impact"
                    src="//imga3.5054399.com/upload_pic/2022/11/1/4399_14191993219.jpg"
                  />
                  Genshin Impact
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Ice Baby and Fire Baby 6"
                    src="//imga1.5054399.com/upload_pic/2021/11/17/4399_09480196660.jpg"
                  />
                  Ice Baby and Fire Baby 6
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Emperor Growth Plan 2H5"
                    src="//imga3.5054399.com/upload_pic/2023/9/23/4399_10522138651.jpg"
                  />

                  Emperor Growth Plan 2H5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="This girl group is a bit scummy"
                    src="//imga1.5054399.com/upload_pic/2022/10/13/4399_17432889047.jpg"
                  />

                  This girl group is a bit scummy

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Delusion about mountains and seas"
                    src="//imga4.5054399.com/upload_pic/2023/9/4/4399_10261975577.jpg"
                  />

                  Delusion about mountains and seas

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Brave Tea Egg"
                    src="//imga5.5054399.com/upload_pic/2023/8/17/4399_10181502410.jpg"
                  />
                  Brave Tea Egg
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Above All Things: Awakening"
                    src="//imga1.5054399.com/upload_pic/2023/9/19/4399_11543318016.jpg"
                  />

                  Above All Things: Awakening

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Cube World Chicken Dinner"
                    src="//imga5.5054399.com/upload_pic/2019/s/202604.jpg"
                  />

                  Cube World Chicken Dinner

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="play a ball"
                    src="//imga3.5054399.com/upload_pic/2021/2/8/4399_10375681866.jpg"
                  />
                  play a ball
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="street basketball master"
                    src="//imga4.5054399.com/upload_pic/2023/5/24/4399_11540432510.jpg"
                  />

                  street basketball master

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Dream Battle"
                    src="//imga1.5054399.com/upload_pic/2022/11/18/4399_16233125537.jpg"
                  />
                  Dream Battle
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="super alloy warrior"
                    src="//imga3.5054399.com/upload_pic/2023/7/20/4399_17195118257.jpg"
                  />

                  super alloy warrior

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="doomsday village"
                    src="//imga2.5054399.com/upload_pic/2023/8/9/4399_14580792266.jpg"
                  />
                  doomsday village
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Food vs. Rats"
                    src="//imga3.5054399.com/upload_pic/2023/3/23/4399_18382710248.jpg"
                  />
                  Food vs. Rats
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="God controls the world"
                    src="//imga2.5054399.com/upload_pic/2022/1/26/4399_15363654370.jpg"
                  />

                  God controls the world

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Stickman playing badminton 3H5"
                    src="//imga1.5054399.com/upload_pic/2022/11/8/4399_15212737520.jpg"
                  />

                  Stickman playing badminton 3H5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Three Kingdoms Kill"
                    src="//imga5.5054399.com/upload_pic/2023/4/7/4399_15413094698.jpg"
                  />

                  4399 Three Kingdoms Kill

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Ancient ruins"
                    src="//imga5.5054399.com/upload_pic/2023/5/24/4399_14034794458.jpg"
                  />
                  Ancient ruins
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="I was a princess in ancient times"
                    src="//imga3.5054399.com/upload_pic/2023/3/22/4399_08413921428.jpg"
                  />

                  I was a princess in ancient times

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Parkour boy travels in Hong Kong"
                    src="//imga4.5054399.com/upload_pic/2022/8/22/4399_16374115232.jpg"
                  />

                  Parkour boy travels in Hong Kong

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Little Commando"
                    src="//imga1.5054399.com/upload_pic/2022/6/24/4399_10470602028.jpg"
                  />

                  4399 Little Commando

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Blade of the Brave"
                    src="//imga2.5054399.com/upload_pic/2022/8/16/4399_15391093297.jpg"
                  />

                  Blade of the Brave

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <span className="hot" />
                  <img
                    alt="4399 graffiti drawing board"
                    src="//imga4.5054399.com/upload_pic/2016/2/29/4399_17484422351.jpg"
                  />

                  4399 graffiti drawing board

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Adventure King 3OL"
                    src="//imga2.5054399.com/upload_pic/2023/5/6/4399_09072666134.jpg"
                  />

                  Adventure King 3OL

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Fairy Tail VS One Piece 2.0"
                    src="//imga1.5054399.com/upload_pic/2018/6/21/4399_15211408152.jpg"
                  />

                  Fairy Tail VS One Piece 2.0

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Pika Hall Playing House"
                    src="//imga2.5054399.com/upload_pic/2023/7/17/4399_14173656552.jpg"
                  />

                  Pika Hall Playing House

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="play resentment"
                    src="//imga1.5054399.com/upload_pic/2023/8/4/4399_11045892819.jpg"
                  />
                  play resentment
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Mrs. Jiang has a daughter"
                    src="//imga1.5054399.com/upload_pic/2022/10/31/4399_15032436909.jpg"
                  />

                  Mrs. Jiang has a daughter

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399Touch touch"
                    src="//imga5.5054399.com/upload_pic/2023/8/23/4399_17543457648.jpg"
                  />
                  4399Touch touch
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="etheric light"
                    src="//imga5.5054399.com/upload_pic/2023/1/20/4399_10063755359.jpg"
                  />
                  etheric light
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Swordsman Legend"
                    src="//imga5.5054399.com/upload_pic/2023/6/26/4399_16300408532.jpg"
                  />
                  Swordsman Legend
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Peak Tank"
                    src="//imga5.5054399.com/upload_pic/2020/10/13/4399_17491839567.jpg"
                  />
                  4399 Peak Tank
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="sky dance"
                    src="//imga4.5054399.com/upload_pic/2023/8/17/4399_14345689013.jpg"
                  />
                  sky dance
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="hidden files"
                    src="//imga3.5054399.com/upload_pic/2023/7/12/4399_17285811077.jpg"
                  />
                  hidden files
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Doomsday Force"
                    src="//imga5.5054399.com/upload_pic/2023/2/20/4399_15334911157.jpg"
                  />
                  Doomsday Force
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Cute version of Bubble Hall 8"
                    src="//imga2.5054399.com/upload_pic/2023/8/1/4399_15114926062.jpg"
                  />

                  Cute version of Bubble Hall 8

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Little crocodile loves taking a bath H5"
                    src="//imga1.5054399.com/upload_pic/2017/1/24/4399_10181217218.jpg"
                  />

                  Little crocodile loves taking a bath H5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Stickman Escape Diary"
                    src="//imga5.5054399.com/upload_pic/2021/7/8/4399_14140212587.jpg"
                  />

                  Stickman Escape Diary

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="LeDongQiuQiu"
                    src="//imga5.5054399.com/upload_pic/2019/12/4/4399_17372187246.jpg"
                  />
                  LeDongQiuQiu
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Mom locked me in the house 2"
                    src="//imga3.5054399.com/upload_pic/2021/7/29/4399_15455520563.jpg"
                  />

                  Mom locked me in the house 2

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Mysterious journey"
                    src="//imga4.5054399.com/upload_pic/2023/7/8/4399_10255719179.jpg"
                  />

                  Mysterious journey

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Legendary gun king"
                    src="//imga3.5054399.com/upload_pic/2023/5/17/4399_15434454923.jpg"
                  />

                  Legendary gun king

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="life restart simulator"
                    src="//imga1.5054399.com/upload_pic/2021/9/23/4399_16215646155.jpg"
                  />

                  life restart simulator

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Desert Island Gun Training Resurrection Battle"
                    src="//imga3.5054399.com/upload_pic/2019/s/201312.jpg"
                  />

                  Desert Island Gun Training Resurrection Battle

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Lying down simulator"
                    src="//imga4.5054399.com/upload_pic/2022/3/14/4399_19151864673.jpg"
                  />

                  Lying down simulator

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Journey to the West"
                    src="//imga1.5054399.com/upload_pic/2023/1/18/4399_10082802741.jpg"
                  />

                  Journey to the West

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="The strongest dog head"
                    src="//imga1.5054399.com/upload_pic/2023/4/27/4399_16103382128.jpg"
                  />

                  The strongest dog head

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Stickman Trial Ground 7"
                    src="//imga1.5054399.com/upload_pic/2022/11/3/4399_16180385575.jpg"
                  />

                  Stickman Trial Ground 7

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="National academic champion"
                    src="//imga5.5054399.com/upload_pic/2023/7/24/4399_13465917662.jpg"
                  />

                  National academic champion

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Bear Infested Wild Athletics"
                    src="//imga5.5054399.com/upload_pic/2021/6/16/4399_17313266730.jpg"
                  />

                  Bear Infested Wild Athletics

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="100 days of survival"
                    src="//imga1.5054399.com/upload_pic/2021/11/19/4399_14202213081.jpg"
                  />

                  100 days of survival

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Life and Death Sniper Zombie Frontline"
                    src="//imga2.5054399.com/upload_pic/2023/9/15/4399_16065523660.jpg"
                  />

                  Life and Death Sniper Zombie Frontline

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Cat Leo H5"
                    src="//imga2.5054399.com/upload_pic/2022/11/10/4399_17214955926.jpg"
                  />
                  Cat Leo H5
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Shocking general"
                    src="//imga4.5054399.com/upload_pic/2023/9/21/4399_09280108394.jpg"
                  />
                  Shocking general
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Hot Basketball Game 2020"
                    src="//imga5.5054399.com/upload_pic/2022/11/10/4399_17085620340.jpg"
                  />

                  Hot Basketball Game 2020

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Ant Evolution 3D"
                    src="//imga4.5054399.com/upload_pic/2023/1/17/4399_19351964069.jpg"
                  />
                  Ant Evolution 3D
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="3D tanks"
                    src="//imga4.5054399.com/upload_pic/2023/1/5/4399_10354837646.jpg"
                  />
                  3D tanks
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Tank Turmoil 2"
                    src="//imga4.5054399.com/upload_pic/2016/2/26/4399_10083293797.jpg"
                  />
                  Tank Turmoil 2
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Rapid Chameleon"
                    src="//imga5.5054399.com/upload_pic/2017/6/13/4399_15192770466.jpg"
                  />
                  Rapid Chameleon
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Today is the king"
                    src="//imga5.5054399.com/upload_pic/2022/5/7/4399_15213405927.jpg"
                  />

                  Today is the king

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Everyone’s Gunslinger: Border King"
                    src="//imga3.5054399.com/upload_pic/2021/2/3/4399_15125506077.jpg"
                  />

                  Everyone’s Gunslinger: Border King

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Troubled Times Elite"
                    src="//imga4.5054399.com/upload_pic/2023/6/29/4399_19493234436.jpg"
                  />

                  Troubled Times Elite

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Eileen's Psalms"
                    src="//imga5.5054399.com/upload_pic/2023/9/23/4399_12294797600.jpg"
                  />
                  Eileen's Psalms
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Zombies, don't drag them"
                    src="//imga4.5054399.com/upload_pic/2022/9/8/4399_16595172159.jpg"
                  />

                  Zombies, don't drag them

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Don't mess with farmers"
                    src="//imga4.5054399.com/upload_pic/2022/7/22/4399_16201108800.jpg"
                  />

                  Don't mess with farmers

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="The Path of the Brave"
                    src="//imga2.5054399.com/upload_pic/2019/3/26/4399_16554656111.jpg"
                  />

                  The Path of the Brave

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Can Battle"
                    src="//imga5.5054399.com/upload_pic/2023/8/11/4399_15484897857.jpg"
                  />
                  Can Battle
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Open a sushi restaurant"
                    src="//imga5.5054399.com/upload_pic/2022/9/26/4399_13534205916.jpg"
                  />

                  Open a sushi restaurant

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="True Death VS Naruto Kizuna"
                    src="//imga2.5054399.com/upload_pic/2023/7/11/4399_16375267271.jpg"
                  />

                  True Death VS Naruto Kizuna

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="attack kanji"
                    src="//imga1.5054399.com/upload_pic/2023/4/17/4399_14342854186.jpg"
                  />
                  attack kanji
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Sky City Adventure H5"
                    src="//imga3.5054399.com/upload_pic/2020/3/31/4399_15112251926.jpg"
                  />

                  Sky City Adventure H5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Quick knife to cut fruit 3H5"
                    src="//imga1.5054399.com/upload_pic/2016/2/25/4399_09264656496.jpg"
                  />

                  Quick knife to cut fruit 3H5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Fantasy Heraldry 4.6"
                    src="//imga5.5054399.com/upload_pic/2022/1/19/4399_11374638283.jpg"
                  />

                  Fantasy Heraldry 4.6

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Three Kingdoms hahaha"
                    src="//imga5.5054399.com/upload_pic/2023/1/18/4399_14102190575.jpg"
                  />

                  Three Kingdoms hahaha

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Life Simulator Chinese Life"
                    src="//imga2.5054399.com/upload_pic/2023/4/20/4399_17271963145.jpg"
                  />

                  Life Simulator Chinese Life

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Weird stories about rules"
                    src="//imga2.5054399.com/upload_pic/2023/7/12/4399_14412525130.jpg"
                  />

                  Weird stories about rules

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Desert Island Survival Diary"
                    src="//imga2.5054399.com/upload_pic/2021/9/15/4399_15345912053.jpg"
                  />

                  Desert Island Survival Diary

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Stickman vs. God Death Battle"
                    src="//imga2.5054399.com/upload_pic/2021/2/7/4399_17034504461.jpg"
                  />

                  Stickman vs. God Death Battle

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="High Building Demolition 3 Invincible Edition"
                    src="//imga4.5054399.com/upload_pic/2016/2/25/4399_09265948256.jpg"
                  />

                  High Building Demolition 3 Invincible Edition

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Two-player World of Tanks 3D"
                    src="//imga3.5054399.com/upload_pic/2016/10/12/4399_16360751958.jpg"
                  />

                  Two-player World of Tanks 3D

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Mini Motorcycle Challenge"
                    src="//imga4.5054399.com/upload_pic/2020/9/23/4399_17304218418.jpg"
                  />

                  Mini Motorcycle Challenge

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Cultivation Simulator"
                    src="//imga3.5054399.com/upload_pic/2022/1/14/4399_14492878754.jpg"
                  />

                  Cultivation Simulator

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Stickman Fighting Evolution 2"
                    src="//imga2.5054399.com/upload_pic/2023/5/19/4399_16325356460.jpg"
                  />

                  Stickman Fighting Evolution 2

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="ball couple"
                    src="//imga2.5054399.com/upload_pic/2019/s/201721.jpg"
                  />
                  ball couple
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="world archery championship"
                    src="//imga2.5054399.com/upload_pic/2019/s/196181.jpg"
                  />

                  world archery championship

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Pizza Prince 2"
                    src="//imga2.5054399.com/upload_pic/2020/1/10/4399_15342250960.jpg"
                  />
                  Pizza Prince 2
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Chinese chess endgame h5"
                    src="//imga1.5054399.com/upload_pic/2023/1/28/4399_15253008891.jpg"
                  />

                  Chinese chess endgame h5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Snake multiplayer showdown"
                    src="//imga1.5054399.com/upload_pic/2021/7/12/4399_17330291306.jpg"
                  />

                  Snake multiplayer showdown

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="space escape"
                    src="//imga3.5054399.com/upload_pic/2023/4/21/4399_10451935706.jpg"
                  />
                  space escape
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="sand sculpture showdown"
                    src="//imga3.5054399.com/upload_pic/2023/6/26/4399_15164271241.jpg"
                  />

                  sand sculpture showdown

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="apocalyptic hero"
                    src="//imga3.5054399.com/upload_pic/2020/2/11/4399_13521105759.jpg"
                  />
                  apocalyptic hero
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Crazy villain fight"
                    src="//imga3.5054399.com/upload_pic/2016/2/25/4399_09230286165.jpg"
                  />

                  Crazy villain fight

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Ice baby and fire baby"
                    src="//imga2.5054399.com/upload_pic/2019/s/198021.jpg"
                  />

                  Ice baby and fire baby

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Transformers War"
                    src="//imga4.5054399.com/upload_pic/2016/2/25/4399_09402752826.jpg"
                  />
                  Transformers War
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Dad's Sushi Restaurant Chinese version"
                    src="//imga1.5054399.com/upload_pic/2017/6/8/4399_17302352700.jpg"
                  />

                  Dad's Sushi Restaurant Chinese version

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="front line conflict"
                    src="//imga5.5054399.com/upload_pic/2023/4/25/4399_17373870910.jpg"
                  />

                  front line conflict

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="I'm a god"
                    src="//imga4.5054399.com/upload_pic/2023/5/15/4399_15350447285.jpg"
                  />
                  I'm a god
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Tea Egg Adventure"
                    src="//imga1.5054399.com/upload_pic/2022/12/23/4399_17470230557.jpg"
                  />

                  Tea Egg Adventure

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="The fleeing turkey"
                    src="//imga5.5054399.com/upload_pic/2016/2/25/4399_09244586314.jpg"
                  />

                  The fleeing turkey

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Slime Lab 2H5"
                    src="//imga5.5054399.com/upload_pic/2023/1/28/4399_17581198929.jpg"
                  />
                  Slime Lab 2H5
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Plants vs. Zombies Invincible Edition"
                    src="//imga5.5054399.com/upload_pic/2016/2/25/4399_09251835750.jpg"
                  />

                  Plants vs. Zombies Invincible Edition

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Path of the Brave: Elf Story Quick Upgrade Edition"
                    src="//imga2.5054399.com/upload_pic/2016/2/25/4399_09250759645.jpg"
                  />

                  Path of the Brave: Elf Story Quick Upgrade Edition

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Stacked Immortal Cultivation Notes"
                    src="//imga3.5054399.com/upload_pic/2022/7/28/4399_19215354980.jpg"
                  />

                  Stacked Immortal Cultivation Notes

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Chicken and Gun King Battlefield"
                    src="//imga4.5054399.com/upload_pic/2023/3/24/4399_19112112398.jpg"
                  />

                  Chicken and Gun King Battlefield

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="King of Elites 5V5"
                    src="//imga2.5054399.com/upload_pic/2022/12/1/4399_11365034794.jpg"
                  />

                  King of Elites 5V5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="3D speed racing"
                    src="//imga5.5054399.com/upload_pic/2016/2/19/4399_14125286462.jpg"
                  />
                  3D speed racing
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Two-person rotating racing car H5"
                    src="//imga4.5054399.com/upload_pic/2023/9/5/4399_14553836745.jpg"
                  />

                  Two-person rotating racing car H5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Gold Miner Double Edition H5"
                    src="//imga3.5054399.com/upload_pic/2023/2/15/4399_19272408041.jpg"
                  />

                  Gold Miner Double Edition H5

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Papa's Cookie Sundae Shop"
                    src="//imga5.5054399.com/upload_pic/2021/1/5/4399_16430401229.jpg"
                  />

                  Papa's Cookie Sundae Shop

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Stickman Artifact Battle 3"
                    src="//imga2.5054399.com/upload_pic/2022/2/24/4399_09511673361.jpg"
                  />

                  Stickman Artifact Battle 3

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="I want to eat chicken"
                    src="//imga2.5054399.com/upload_pic/2020/3/27/4399_10184820221.jpg"
                  />

                  I want to eat chicken

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="stickman war legacy 2"
                    src="//imga5.5054399.com/upload_pic/2021/7/1/4399_11400215565.jpg"
                  />

                  stickman war legacy 2

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Asphalt 6"
                    src="//imga5.5054399.com/upload_pic/2019/10/8/4399_15291685960.jpg"
                  />
                  Asphalt 6
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Cool Spin Ninja 2"
                    src="//imga1.5054399.com/upload_pic/2016/2/25/4399_09283901137.jpg"
                  />

                  Cool Spin Ninja 2

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Iron Spider"
                    src="//imga1.5054399.com/upload_pic/2016/2/23/4399_16580765716.jpg"
                  />
                  Iron Spider
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Carrot Defense 3"
                    src="//imga1.5054399.com/upload_pic/2020/1/14/4399_14181208208.jpg"
                  />
                  Carrot Defense 3
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Escape from the hospital is not easy"
                    src="//imga2.5054399.com/upload_pic/2020/3/23/4399_13561847731.jpg"
                  />

                  Escape from the hospital is not easy

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="my pancake stall"
                    src="//imga2.5054399.com/upload_pic/2022/5/26/4399_15345424257.jpg"
                  />
                  my pancake stall
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Prince and Princess Returning Home"
                    src="//imga5.5054399.com/upload_pic/2016/2/20/4399_16034287109.jpg"
                  />

                  Prince and Princess Returning Home

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Chaos Shootout New Chinese Version"
                    src="//imga1.5054399.com/upload_pic/2017/6/9/4399_08402136521.jpg"
                  />

                  Chaos Shootout New Chinese Version

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Assault showdown invincible version"
                    src="//imga1.5054399.com/upload_pic/2016/2/25/4399_09243481100.jpg"
                  />

                  Assault showdown invincible version

                </a>
              </li>
            </ul>
          </div>
          <div className="tm_fun h_4">
            <div className="tm_tit">
              <span className="tit_ico2" />
              <a target={'_blank'} rel="noreferrer" className="tit_a" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">

                4399 Featured Games

              </a>
              {/* <a target={'_blank'} rel="noreferrer"  className="more_1" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" /> */}
            </div>
            <ul className="tm_list">
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Honkai Impact 3"
                    src="//imga3.5054399.com/upload_pic/2020/12/21/4399_10370463359.jpg"
                  />
                  Honkai Impact 3
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Titanium Warrior"
                    src="//imga3.5054399.com/upload_pic/2023/2/17/4399_16590047758.jpg"
                  />

                  4399 Titanium Warrior

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="300 heroes"
                    src="//imga3.5054399.com/upload_pic/2022/9/7/4399_17315837375.jpg"
                  />
                  300 heroes
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Dragon and Home"
                    src="//imga2.5054399.com/upload_pic/2022/3/4/4399_10043346304.jpg"
                  />

                  4399 Dragon and Home

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Time and Space Summoning"
                    src="//imga3.5054399.com/upload_pic/2021/2/10/4399_09304055495.jpg"
                  />

                  4399 Time and Space Summoning

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Honkai Impact: Star Railroad"
                    src="//imga4.5054399.com/upload_pic/2023/4/23/4399_11205244482.jpg"
                  />

                  Honkai Impact: Star Railroad

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Peak Battleship"
                    src="//imga1.5054399.com/upload_pic/2018/2/1/4399_11472294501.jpg"
                  />

                  4399 Peak Battleship

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <span className="hot" />
                  <img
                    alt="4399 graffiti drawing board"
                    src="//imga4.5054399.com/upload_pic/2016/2/29/4399_17484422351.jpg"
                  />

                  4399 graffiti drawing board

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Heroic Soul Blade"
                    src="//imga1.5054399.com/upload_pic/2023/7/26/4399_14425029070.jpg"
                  />

                  4399 Heroic Soul Blade

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Three Kingdoms Kill"
                    src="//imga5.5054399.com/upload_pic/2023/4/7/4399_15413094698.jpg"
                  />

                  4399 Three Kingdoms Kill

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 Le Dou Qi Bing"
                    src="//imga1.5054399.com/upload_pic/2023/7/28/4399_10512489887.jpg"
                  />

                  4399 Le Dou Qi Bing

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="4399 milk block"
                    src="//imga2.5054399.com/upload_pic/2021/10/12/4399_15350919727.jpg"
                  />
                  4399 milk block
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Creation and magic"
                    src="//imga4.5054399.com/upload_pic/2023/8/11/4399_14422154356.jpg"
                  />

                  Creation and magic

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Food vs. Rats"
                    src="//imga3.5054399.com/upload_pic/2023/3/23/4399_18382710248.jpg"
                  />
                  Food vs. Rats
                </a>
              </li>
            </ul>
          </div>
          <div className="tm_fun h_4">
            <div className="tm_tit">
              <span className="tit_ico2" />
              <a target={'_blank'} rel="noreferrer" className="tit_a" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                H5 game selection
              </a>
              {/* <a target={'_blank'} rel="noreferrer"  className="more_1" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" /> */}
            </div>
            <ul className="tm_list">
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="New Douluo Continent"
                    src="//imga3.5054399.com/upload_pic/2023/9/21/4399_11344286433.jpg"
                  />

                  New Douluo Continent

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="twin fantasy"
                    src="//imga3.5054399.com/upload_pic/2023/8/22/4399_16380755819.jpg"
                  />
                  twin fantasy
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Pocket Different World"
                    src="//imga5.5054399.com/upload_pic/2022/11/23/4399_15020137763.jpg"
                  />

                  Pocket Different World

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Super billiards master"
                    src="//imga2.5054399.com/upload_pic/2023/1/3/4399_14450313589.jpg"
                  />

                  Super billiards master

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Happy Three Kingdoms Killing"
                    src="//imga4.5054399.com/upload_pic/2023/8/4/4399_11274729050.jpg"
                  />

                  Happy Three Kingdoms Killing

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Dreaming back to Bai Yujing"
                    src="//imga5.5054399.com/upload_pic/2023/9/18/4399_15215116448.jpg"
                  />

                  Dreaming back to Bai Yujing

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Who is the richest man"
                    src="//imga3.5054399.com/upload_pic/2021/12/30/4399_16234233285.jpg"
                  />

                  Who is the richest man

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="怼怼梦三国(怼怼三国)"
                    src="//imga1.5054399.com/upload_pic/2023/9/6/4399_17345490457.jpg"
                  />

                  怼怼梦三国(怼怼三国)

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Youth in the prosperous age"
                    src="//imga1.5054399.com/upload_pic/2023/4/10/4399_16202173114.jpg"
                  />

                  Youth in the prosperous age

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Super Poke Ball"
                    src="//imga2.5054399.com/upload_pic/2021/7/1/4399_09482411700.jpg"
                  />
                  Super Poke Ball
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Her Majesty the Queen, raised by all the people"
                    src="//imga5.5054399.com/upload_pic/2023/8/3/4399_14133402580.jpg"
                  />

                  Her Majesty the Queen, raised by all the people

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="gold coin tycoon"
                    src="//imga4.5054399.com/upload_pic/2020/1/7/4399_17105754471.jpg"
                  />
                  gold coin tycoon
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Hero Growth Plan"
                    src="//imga5.5054399.com/upload_pic/2023/6/7/4399_11132453090.jpg"
                  />
                  Hero Growth Plan
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="little elf"
                    src="//imga4.5054399.com/upload_pic/2022/8/29/4399_11251452251.jpg"
                  />
                  little elf
                </a>
              </li>
            </ul>
          </div>
          <div className="evn_box">
            <div className="evn_week">
              <span className="tit_ico4" />
              {/* <a target={'_blank'} rel="noreferrer"  className="more_3" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" /> */}
              <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">

                4399Activities in this issue

              </a>
            </div>
            <ul className="evn_ul">
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt='"Egg Boy Party" Welfare Center'
                    src="//imga2.5054399.com/upload_pic/2023/9/26/4399_09390064751.jpg"
                  />

                  "Egg Boy Party" Welfare Center

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Register for a role and enjoy platform benefits immediately"
                    src="//imga4.5054399.com/upload_pic/2023/9/4/4399_14463753708.jpg"
                  />

                  Register for a role and enjoy platform benefits immediately

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="New team battle mode starts"
                    src="//imga2.5054399.com/upload_pic/2023/9/15/4399_17410327079.jpg"
                  />

                  New team battle mode starts

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Participate in activities to win peripherals and gift pack rewards"
                    src="//imga2.5054399.com/upload_pic/2023/9/26/4399_09461046657.jpg"
                  />

                  Participate in activities to win peripherals and gift pack
                  rewards

                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="box_l">
          {/*今日推荐*/}
          <div className="rec_left cf">
            <div className="top_tit">
              <span className="tit_ico2" />
              <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="tit_a">

                Today's recommendation

              </a>
              {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="more_1" /> */}
            </div>
            <ul className="rec_ul" id="fla_game">
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  className="rec_img"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Youth in the prosperous age"
                    src="//imga2.5054399.com/upload_pic/2023/9/25/4399_15415152936.png"
                  />
                </a>{" "}
                <p>
                  <em>
                    <a target={'_blank'} rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                    >

                      Youth in the prosperous age

                    </a>
                  </em>
                  <a target={'_blank'} rel="noreferrer" className="p_ac" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">

                    Travel through the prosperous Tang Dynasty and relive the
                    beauty of ancient costumes

                  </a>
                </p>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  className="rec_img"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="The Snow King has arrived"
                    src="//imga5.5054399.com/upload_pic/2023/9/23/4399_11332337823.jpg"
                  />
                </a>{" "}
                <p>
                  <em>
                    <a target={'_blank'} rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                    >

                      The Snow King has arrived

                    </a>
                  </em>
                  <a target={'_blank'} rel="noreferrer" className="p_ac" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">

                    The story of Snow King coming to Hunting Valley to find the
                    stolen ice cream scepter!

                  </a>
                </p>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  className="rec_img"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="4399 Heroes of the Three Kingdoms"
                    src="//imga.5054399.com/upload_pic/2020/8/19/4399_15503247895.jpg"
                  />
                </a>{" "}
                <p>
                  <em>
                    <a target={'_blank'} rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                    >

                      4399 Heroes of the Three Kingdoms

                    </a>
                  </em>
                  <a target={'_blank'} rel="noreferrer" className="p_ac" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">

                    Real battlefield, strategy is king, compete for the world and
                    unify the world

                  </a>
                </p>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  className="rec_img"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Thunder Fury Dragon King Edition"
                    src="//imga4.5054399.com/upload_pic/2023/9/25/4399_15201946135.jpg"
                  />
                </a>{" "}
                <p>
                  <em>
                    <a target={'_blank'} rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                    >

                      Thunder Fury Dragon King Edition

                    </a>
                  </em>
                  <a target={'_blank'} rel="noreferrer" className="p_ac" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">

                    Romantic and legendary road, classic brotherhood!

                  </a>
                </p>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  className="rec_img"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="4399 Hundred Battles Sand City"
                    src="//imga5.5054399.com/upload_pic/2022/1/19/4399_15355875123.jpg"
                  />
                </a>{" "}
                <p>
                  <em>
                    <a target={'_blank'} rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                    >

                      4399 Hundred Battles Sand City

                    </a>
                  </em>
                  <a target={'_blank'} rel="noreferrer" className="p_ac" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">

                    Passionate attack on the sand, brothers gathered to achieve an
                    immortal legend

                  </a>
                </p>{" "}
              </li>
            </ul>
          </div>
          <div className="rec_top cf">
            <div className="top_tit">
              <span className="tit_ico2" />
              <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="tit_a">
                Recommended topics
              </a>
              {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="more_1" /> */}
            </div>
            <ul className="top_ul" id="specialcommend1">
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga2.5054399.com/upload_pic/2014/6/18/4399_16265023726.jpg" />

                  Two-player mini-game

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img alt='' src="//imga2.5054399.com/upload_pic/2014/6/18/4399_16265675485.gif" />

                  Cartoon collection

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2014/9/23/4399_11425908884.jpg" />

                  Invincible version

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2023/5/22/4399_17570068636.jpg" />
                  Cloud gaming
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2017/5/20/4399_14160014245.jpg" />

                  Web game collection

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2014/5/15/4399_17105762559.jpg" />

                  Lianliankan complete works

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2014/5/15/4399_17135804543.jpg" />
                  tank
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2014/8/19/4399_17531158651.jpg" />
                  baby
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga1.5054399.com/upload_pic/2015/5/19/4399_14431636880.jpg" />
                  3D games
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2020/12/4/4399_17055123564.jpg" />
                  Princess
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2014/5/15/4399_17065547201.jpg" />

                  Big fish eats small fish

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga3.5054399.com/upload_pic/2015/11/17/4399_14283754180.jpg" />
                  spongebob
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2016/5/11/4399_13453931443.jpg" />
                  Assembly topic
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2018/7/16/4399_09082451111.jpg" />
                  football
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga3.5054399.com/upload_pic/2014/9/23/4399_11233171018.jpg" />
                  Solve puzzles
                </a>
              </li>
            </ul>
            <ul className="top_ul" id="specialcommend2" style={{ display: "none" }}>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga1.5054399.com/upload_pic/2019/9/25/4399_14152936947.jpg" />
                  escape room
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2014/9/23/4399_11365775606.jpg" />
                  individual
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2014/7/21/4399_10421217995.jpg" />
                  Gold miners set
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2014/9/23/4399_11422961194.jpg" />

                  tower defense game

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga1.5054399.com/upload_pic/2016/7/13/4399_14543151511.jpg" />

                  Selected Games for Boys

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga1.5054399.com/upload_pic/2019/4/26/4399_10494134345.jpg" />
                  break through
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga1.5054399.com/upload_pic/2019/4/26/4399_10500977703.jpg" />

                  work to earn money

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2020/12/4/4399_17052527783.jpg" />
                  pet
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga3.5054399.com/upload_pic/2020/12/4/4399_17045417423.jpg" />
                  Cook
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga1.5054399.com/upload_pic/2015/11/23/4399_09555821813.jpg" />
                  counter strike
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga5.5054399.com/upload_pic/2014/6/19/4399_15231590648.jpg" />
                  SUV
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2014/9/23/4399_11380747847.jpg" />
                  fighting
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga4.5054399.com/upload_pic/2014/5/15/4399_17071492535.jpg" />

                  Cakes &amp; Burgers

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga3.5054399.com/upload_pic/2014/9/23/4399_11265620648.jpg" />
                  car
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  {" "}
                  <img alt='' src="//imga1.5054399.com/upload_pic/2014/9/23/4399_11274656922.jpg" />
                  three people
                </a>
              </li>
            </ul>
            {/* <div id="specialcommend3" className="new_pag">
              <a target={'_blank'} rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                className="up_1 uon"
              />
              <a target={'_blank'} rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                className="do_1"
              />
            </div> */}
          </div>
          <div className="asp_box cf">
            <div className="tm_tit asp_week">
              <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="tit_a yun_tit">
                Cloud gaming
              </a>
              {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="more_1" /> */}
            </div>
            <a target={'_blank'} rel="noreferrer"
              className="asp_img"
              href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
            >
              <img
                alt="Cloud gaming"
                src="//imga2.5054399.com/upload_pic/2023/9/25/4399_16284738918.jpg"
              />
            </a>
            <ul className="wd_ul">
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Dream Westward Journey OL cloud game"
                    src="//imga2.5054399.com/upload_pic/2023/5/26/4399_11361642113.jpg"
                  />

                  Dream Westward Journey OL cloud game

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="azur lane"
                    src="//imga3.5054399.com/upload_pic/2023/5/26/4399_11350662156.jpg"
                  />
                  azur lane
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Purcell plans cloud gaming"
                    src="//imga1.5054399.com/upload_pic/2023/7/14/4399_15250406387.jpg"
                  />

                  Purcell plans cloud gaming

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Crystal Core Cloud Games"
                    src="//imga1.5054399.com/upload_pic/2023/8/4/4399_14250086177.jpg"
                  />

                  Crystal Core Cloud Games

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Delusion about mountains and seas"
                    src="//imga2.5054399.com/upload_pic/2023/9/4/4399_10263392470.jpg"
                  />

                  Delusion about mountains and seas

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  <img
                    alt="Yunyuan Shen"
                    src="//imga1.5054399.com/upload_pic/2023/5/22/4399_17483243820.jpg"
                  />
                  Yunyuan Shen
                </a>
              </li>
            </ul>
          </div>
          <div className="rec_left cf mt10">
            <div className="top_tit">
              <span className="phone" />
              <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="tit_a">
                mobile game
              </a>
              {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="more_1" /> */}
            </div>
            <ul className="phone_ul cf">
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="mini world"
                    src="//f1.img4399.com/ma~1716_230925154710_Suv3.jpeg"
                  />
                  mini world
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Subway parkour"
                    src="//f1.img4399.com/ma~1716_230925154818_hxET.png"
                  />
                  Subway parkour
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="key to time"
                    src="//f1.img4399.com/ma~1716_230925155033_yIWX.jpeg"
                  />
                  key to time
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Emperor's Growth Plan 2"
                    src="//f1.img4399.com/ma~1716_230925155100_ohTy.jpeg"
                  />

                  Emperor's Growth Plan 2

                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="ancient soul"
                    src="//f1.img4399.com/ma~1716_230925155126_xsfK.jpeg"
                  />
                  ancient soul
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="fantasy tower"
                    src="//f1.img4399.com/ma~1716_230925155202_Jj2f.jpeg"
                  />
                  fantasy tower
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="my origin"
                    src="//f1.img4399.com/ma~1716_230925155230_Zg1b.jpeg"
                  />
                  my origin
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="space operations"
                    src="//f1.img4399.com/ma~1716_230925155302_AgiF.jpeg"
                  />
                  space operations
                </a>
              </li>
              <li>
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                  <img
                    alt="Three Kingdoms Killing"
                    src="//f1.img4399.com/ma~1716_230925155341_yshe.jpeg"
                  />

                  Three Kingdoms Killing

                </a>
              </li>
            </ul>
          </div>
          <div className="rec_top cf">
            <div className="top_tit">
              <span className="tit_ico2" />
              <a target={'_blank'} rel="noreferrer" className="tit_a" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                Web games
              </a>
              {/* <a target={'_blank'} rel="noreferrer"  className="more_1" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" /> */}
            </div>
            <div id="webgamediv">
              <ul className="wd_ul" id="webgame1">
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 Heroes of the Three Kingdoms"
                      src="//imga1.5054399.com/upload_pic/2020/8/20/4399_11085565686.jpg"
                    />

                    4399 Heroes of the Three Kingdoms

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 Viking Legend"
                      src="//imga.5054399.com/upload_pic/2023/8/21/4399_16310709609.jpg"
                    />

                    4399 Viking Legend

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                    <img
                      alt="4399 Ancient Legend"
                      src="//imga4.5054399.com/upload_pic/2023/3/6/4399_10033405863.jpg"
                    />

                    4399 Ancient Legend

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399Heart of the King 2"
                      src="//imga5.5054399.com/upload_pic/2023/8/23/4399_17372832293.jpg"
                    />

                    4399Heart of the King 2

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399Touch touch"
                      src="//imga2.5054399.com/upload_pic/2019/10/23/4399_10154428767.jpg"
                    />

                    4399Touch touch

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                    <img
                      alt="4399 Fairyland"
                      src="//imga1.5054399.com/upload_pic/2023/7/17/4399_17040331581.jpg"
                    />
                    4399 Fairyland
                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                    <img
                      alt="4399 Legendary Overlord"
                      src="//imga3.5054399.com/upload_pic/2021/5/21/4399_15381207997.jpg"
                    />

                    4399 Legendary Overlord

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 Hundred Battles Sand City"
                      src="//imga1.5054399.com/upload_pic/2022/8/2/4399_14552868438.jpg"
                    />

                    4399 Hundred Battles Sand City

                  </a>
                </li>
              </ul>
              <ul className="wd_ul" id="webgame2" style={{ display: "none" }}>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 life and death sniper"
                      src="//imga3.5054399.com/upload_pic/2023/2/20/4399_10252093705.jpg"
                    />

                    4399 life and death sniper

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 FireWire Elite"
                      src="//imga.5054399.com/upload_pic/2017/9/7/4399_16531376915.jpg"
                    />

                    4399 FireWire Elite

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="3D tanks"
                      src="//imga4.5054399.com/upload_pic/2016/11/23/4399_09363612411.jpg"
                    />
                    3D tanks
                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                    <img
                      alt="4399 Dantantang"
                      src="//imga1.5054399.com/upload_pic/2023/9/19/4399_10522334557.jpg"
                    />

                    4399 Dantantang

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 Three Kingdoms Kill"
                      src="//imga3.5054399.com/upload_pic/2020/12/18/4399_11200128777.jpg"
                    />

                    4399 Three Kingdoms Kill

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 The Legend of the Three Kingdoms"
                      src="//imga5.5054399.com/upload_pic/2022/6/24/4399_14192501507.jpg"
                    />

                    4399 The Legend of the Three Kingdoms

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="4399 Ice and Fire Apocalypse"
                      src="//imga2.5054399.com/upload_pic/2023/5/6/4399_11284892360.jpg"
                    />

                    4399 Ice and Fire Apocalypse

                  </a>
                </li>
                <li>
                  <a target={'_blank'} rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  >
                    <img
                      alt="Food vs. Rats"
                      src="//imga1.5054399.com/upload_pic/2017/9/7/4399_16534211016.jpg"
                    />
                    Food vs. Rats
                  </a>
                </li>
              </ul>
              {/* <div id="webgame3" className="new_pag">
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                  className="up_1 uon"
                />
                <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="do_1" />
              </div> */}
            </div>
          </div>
          <div className="rec_left cf mt10">
            <div className="top_tit">
              <span className="tit_ico2" />
              <a target={'_blank'} rel="noreferrer" className="tit_a" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
                client game
              </a>
              <em>

                Simple download in one second!

              </em>
              {/* <a target={'_blank'} rel="noreferrer"  className="more_1" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" /> */}
            </div>
            <ul className="wd_ul">
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="dragon and home"
                    src="//imga1.5054399.com/upload_pic/2022/11/25/4399_21133185849.jpg"
                  />
                  dragon and home
                </a>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="Time and space summons"
                    src="//imga.5054399.com/upload_pic/2022/9/22/4399_17154945349.jpg"
                  />

                  Time and space summons

                </a>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="300 heroes"
                    src="//imga4.5054399.com/upload_pic/2022/7/14/4399_13451650063.jpg"
                  />
                  300 heroes
                </a>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="Heroic Soul Blade"
                    src="//imga2.5054399.com/upload_pic/2022/12/2/4399_10595434877.jpg"
                  />

                  Heroic Soul Blade

                </a>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="Little Commandos 2"
                    src="//imga.5054399.com/upload_pic/2021/7/19/4399_14543111040.jpg"
                  />

                  Little Commandos 2

                </a>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="pinnacle tank"
                    src="//imga2.5054399.com/upload_pic/2020/1/21/4399_17235811186.jpg"
                  />
                  pinnacle tank
                </a>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="Master of Kings"
                    src="//imga3.5054399.com/upload_pic/2022/10/12/4399_17251880752.jpg"
                  />
                  Master of Kings
                </a>{" "}
              </li>
              <li>
                {" "}
                <a target={'_blank'} rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
                >
                  {" "}
                  <img
                    alt="milk lump"
                    src="//imga4.5054399.com/upload_pic/2023/4/25/4399_09405103316.jpg"
                  />
                  milk lump
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div
        style={{ clear: "both", width: 980, padding: "5px 0 0 0", margin: "0 auto" }}
      >
        <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
          <img
            alt="Game box cloud game"
            src="//imga3.5054399.com/upload_pic/2023/6/8/4399_16483421795.jpg"
          />
        </a>
      </div> */}
      <div className="lf_game cf" id="newtj">
        <div className="tm_tit">
          <span className="tit_ico2" />
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="tit_a">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>
                Latest recommended games
              </span>
            </span>
          </a>
          {/* <a target={'_blank'} rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="more_1" /> */}
          <span className="tit_ico5" />
        </div>
        <ul className="tm_list lf_ul">
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="mutated zombies"
                src="//imga3.5054399.com/upload_pic/2023/8/23/4399_17295048131.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>mutated zombies</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="ultimate maze"
                src="//imga5.5054399.com/upload_pic/2023/8/25/4399_11003440099.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>ultimate maze</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="Rolling Ball Water Challenge"
                src="//imga2.5054399.com/upload_pic/2023/8/23/4399_10350476292.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Rolling Ball Water Challenge
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="impossible parkour"
                src="//imga3.5054399.com/upload_pic/2023/8/23/4399_13551683479.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>impossible parkour</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="my supermodel secretary"
                src="//imga1.5054399.com/upload_pic/2023/9/20/4399_17225022843.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  my supermodel secretary
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="Storage every day"
                src="//imga2.5054399.com/upload_pic/2023/8/23/4399_15093186940.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Storage every day</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="I haven't gotten in the car yet"
                src="//imga1.5054399.com/upload_pic/2023/8/14/4399_14291500508.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  I haven't gotten in the car yet
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="Observation challenge"
                src="//imga5.5054399.com/upload_pic/2023/7/13/4399_14572718386.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Observation challenge
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span className="new" />
              <img
                alt="I am Zhiduoxing"
                src="//imga1.5054399.com/upload_pic/2023/7/17/4399_10565381158.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>I am Zhiduoxing</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Yunyuan Shen"
                src="//imga2.5054399.com/upload_pic/2022/11/1/4399_14191993219.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Yunyuan Shen</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Ninjutsu Battle"
                src="//imga2.5054399.com/upload_pic/2023/9/21/4399_14100529170.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Ninjutsu Battle</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="kung fu frog"
                src="//imga5.5054399.com/upload_pic/2023/8/14/4399_17421073523.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>kung fu frog</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Mecha assembly charge"
                src="//imga3.5054399.com/upload_pic/2023/8/11/4399_14365055312.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Mecha assembly charge
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Conquer the city"
                src="//imga4.5054399.com/upload_pic/2023/8/22/4399_15125345235.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Conquer the city</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="football League"
                src="//imga1.5054399.com/upload_pic/2023/8/22/4399_17000554477.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>football League</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Bloom and bear fruit"
                src="//imga4.5054399.com/upload_pic/2023/8/17/4399_17341029811.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Bloom and bear fruit</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="poor eddie"
                src="//imga1.5054399.com/upload_pic/2023/8/17/4399_16440963629.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>poor eddie</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="my sandbox world"
                src="//imga2.5054399.com/upload_pic/2023/8/1/4399_10492627129.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>my sandbox world</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Rampage Heroes Altar"
                src="//imga1.5054399.com/upload_pic/2023/8/14/4399_13445504984.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Rampage Heroes Altar</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="3D Simulation Bridge Designer"
                src="//imga4.5054399.com/upload_pic/2023/7/14/4399_13582272971.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  3D Simulation Bridge Designer
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="The birth of the Brain King"
                src="//imga5.5054399.com/upload_pic/2023/7/12/4399_14005847545.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  The birth of the Brain King
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Chinese character brain circuit"
                src="//imga4.5054399.com/upload_pic/2023/5/11/4399_11371208083.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Chinese character brain circuit
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Critical hit zombie"
                src="//imga3.5054399.com/upload_pic/2023/7/25/4399_19404686325.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Critical hit zombie</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="parkour warrior"
                src="//imga1.5054399.com/upload_pic/2023/9/18/4399_13593133068.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>parkour warrior</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="crazy super bunny"
                src="//imga5.5054399.com/upload_pic/2023/6/8/4399_14382327637.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>crazy super bunny</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Storage expert"
                src="//imga2.5054399.com/upload_pic/2023/7/31/4399_14305041093.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Storage expert</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="king of rags"
                src="//imga4.5054399.com/upload_pic/2023/6/21/4399_15401245929.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>king of rags</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Palace Concubine·Pampered Crown Sixth Palace"
                src="//imga2.5054399.com/upload_pic/2023/2/22/4399_14053494607.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Palace Concubine·Pampered Crown Sixth Palace
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Moyu Company"
                src="//imga4.5054399.com/upload_pic/2023/6/12/4399_14284483784.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Moyu Company</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Fun shootout"
                src="//imga3.5054399.com/upload_pic/2023/8/7/4399_15062456328.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Fun shootout</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Greedy Bug Boy"
                src="//imga1.5054399.com/upload_pic/2023/4/20/4399_15115366325.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Greedy Bug Boy</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="ultimate racer"
                src="//imga4.5054399.com/upload_pic/2023/5/10/4399_10385019260.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>ultimate racer</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Stickman enters the pixel world 5"
                src="//imga4.5054399.com/upload_pic/2023/8/7/4399_15292522304.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Stickman enters the pixel world 5
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="my nail salon"
                src="//imga3.5054399.com/upload_pic/2022/8/4/4399_16295413427.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>my nail salon</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Aircraft Remote Control 3D"
                src="//imga2.5054399.com/upload_pic/2023/7/17/4399_14531810063.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Aircraft Remote Control 3D
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Lao Liu is looking for trouble"
                src="//imga4.5054399.com/upload_pic/2023/7/6/4399_11394986039.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Lao Liu is looking for trouble
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="extreme mountain biking"
                src="//imga5.5054399.com/upload_pic/2022/12/23/4399_10103668077.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  extreme mountain biking
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="The Sims"
                src="//imga2.5054399.com/upload_pic/2023/5/10/4399_15144136237.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>The Sims</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="toilet monster"
                src="//imga1.5054399.com/upload_pic/2023/7/21/4399_16561434089.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>toilet monster</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="water carnival"
                src="//imga1.5054399.com/upload_pic/2022/9/9/4399_15275819027.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>water carnival</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Little Meigou's Life"
                src="//imga5.5054399.com/upload_pic/2023/6/5/4399_17283665655.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Little Meigou's Life</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Adopt a daughter to dress up"
                src="//imga4.5054399.com/upload_pic/2023/8/2/4399_15434883421.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Adopt a daughter to dress up
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Kung Fu Stickman"
                src="//imga5.5054399.com/upload_pic/2022/7/18/4399_15424397455.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Kung Fu Stickman</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Creative people love storage"
                src="//imga2.5054399.com/upload_pic/2023/5/22/4399_14432102016.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Creative people love storage
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Meow meow"
                src="//imga3.5054399.com/upload_pic/2023/5/8/4399_14361844768.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Meow meow</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="off road racing"
                src="//imga2.5054399.com/upload_pic/2022/4/28/4399_16535636217.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>off road racing</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="City car driving"
                src="//imga2.5054399.com/upload_pic/2023/2/2/4399_16054705662.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>City car driving</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Metamorphosis of Amateurs"
                src="//imga5.5054399.com/upload_pic/2023/3/14/4399_17494408787.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Metamorphosis of Amateurs
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="dark raid"
                src="//imga2.5054399.com/upload_pic/2020/1/7/4399_14575420801.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>dark raid</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Roll the ball"
                src="//imga3.5054399.com/upload_pic/2023/3/14/4399_11070552910.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Roll the ball</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="jumping flipping bottle"
                src="//imga4.5054399.com/upload_pic/2023/8/4/4399_16131868133.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  jumping flipping bottle
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Spaceman Battle 2"
                src="//imga3.5054399.com/upload_pic/2022/9/27/4399_16320166623.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Spaceman Battle 2</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Attack and defense battle"
                src="//imga2.5054399.com/upload_pic/2022/10/25/4399_19375238528.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Attack and defense battle
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Stickman God of War"
                src="//imga1.5054399.com/upload_pic/2023/2/23/4399_19070112079.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Stickman God of War</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="City Supercar Racing"
                src="//imga5.5054399.com/upload_pic/2021/7/20/4399_16285626830.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>City Supercar Racing</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Slime Lab H5"
                src="//imga4.5054399.com/upload_pic/2023/1/28/4399_17530836541.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Slime Lab H5</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Real gunfight"
                src="//imga1.5054399.com/upload_pic/2022/10/14/4399_17153250426.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Real gunfight</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="motorcycle driving simulator"
                src="//imga2.5054399.com/upload_pic/2022/3/14/4399_17175897682.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  motorcycle driving simulator
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Night market snacks"
                src="//imga2.5054399.com/upload_pic/2022/9/8/4399_16085946008.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Night market snacks</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="3D helicopter air combat"
                src="//imga5.5054399.com/upload_pic/2023/7/24/4399_14562156301.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  3D helicopter air combat
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="pin simulator"
                src="//imga1.5054399.com/upload_pic/2022/3/29/4399_14504502528.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>pin simulator</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="cut everything"
                src="//imga1.5054399.com/upload_pic/2022/9/24/4399_14253090283.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>cut everything</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="King, don’t be arrogant"
                src="//imga3.5054399.com/upload_pic/2022/7/4/4399_13444796386.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  King, don’t be arrogant
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="sniper sharpshooter"
                src="//imga2.5054399.com/upload_pic/2021/11/12/4399_09585498181.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>sniper sharpshooter</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Hunter's Survival Diary Enhanced Chinese Version"
                src="//imga2.5054399.com/upload_pic/2017/8/22/4399_10250022494.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Hunter's Survival Diary Enhanced Chinese Version
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Dog Adventures"
                src="//imga2.5054399.com/upload_pic/2022/9/21/4399_15342108960.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Dog Adventures</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="astronauts escape"
                src="//imga5.5054399.com/upload_pic/2021/8/28/4399_13584413826.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>astronauts escape</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Killed a sheep"
                src="//imga5.5054399.com/upload_pic/2022/9/20/4399_13364049646.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Killed a sheep</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Fruit cutter master"
                src="//imga2.5054399.com/upload_pic/2023/7/25/4399_19142503489.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Fruit cutter master</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="war simulator"
                src="//imga3.5054399.com/upload_pic/2022/3/1/4399_14485149795.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>war simulator</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Crayon Shin-chan's Adventure 3"
                src="//imga3.5054399.com/upload_pic/2022/6/28/4399_15524051832.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Crayon Shin-chan's Adventure 3
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="King Assassination Challenge"
                src="//imga3.5054399.com/upload_pic/2023/6/7/4399_17405467472.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  King Assassination Challenge
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Stickman parkour game"
                src="//imga5.5054399.com/upload_pic/2021/6/30/4399_14210292093.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Stickman parkour game
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="I want to be a teacher"
                src="//imga1.5054399.com/upload_pic/2022/1/6/4399_10015283400.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  I want to be a teacher
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Sister wants to lose weight"
                src="//imga4.5054399.com/upload_pic/2021/11/24/4399_17052139330.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Sister wants to lose weight
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Classic Sudoku Challenge"
                src="//imga4.5054399.com/upload_pic/2021/1/14/4399_11164536972.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Classic Sudoku Challenge
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Dream Hunter Dormitory"
                src="//imga3.5054399.com/upload_pic/2022/8/5/4399_16023432693.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Dream Hunter Dormitory
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Passenger bus driver"
                src="//imga2.5054399.com/upload_pic/2022/5/20/4399_16351471139.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Passenger bus driver</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="3D billiards"
                src="//imga5.5054399.com/upload_pic/2021/5/10/4399_17205578612.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>3D billiards</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Stickman Shootout Showdown"
                src="//imga5.5054399.com/upload_pic/2022/1/27/4399_16150576717.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Stickman Shootout Showdown
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Sniper training camp 2"
                src="//imga4.5054399.com/upload_pic/2019/12/19/4399_14334179183.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Sniper training camp 2
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="dancing balls"
                src="//imga5.5054399.com/upload_pic/2019/s/206399.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>dancing balls</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Stickman Super Spider-Man"
                src="//imga2.5054399.com/upload_pic/2021/2/1/4399_15252922817.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Stickman Super Spider-Man
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="shark territory"
                src="//imga3.5054399.com/upload_pic/2021/6/4/4399_11074777080.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>shark territory</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Plants vs. Zombies Invincible Strategy Edition 2"
                src="//imga2.5054399.com/upload_pic/2016/2/26/4399_10133159415.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Plants vs. Zombies Invincible Strategy Edition 2
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Weird rumor board"
                src="//imga2.5054399.com/upload_pic/2021/7/3/4399_14264056849.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Weird rumor board</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Monster Rising 2"
                src="//imga4.5054399.com/upload_pic/2020/12/7/4399_17004660511.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Monster Rising 2</span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Cut the rope H5 version"
                src="//imga5.5054399.com/upload_pic/2022/11/22/4399_17444142457.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Cut the rope H5 version
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Temple Run 2 Holi Festival"
                src="//imga5.5054399.com/upload_pic/2021/6/28/4399_17171736232.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>
                  Temple Run 2 Holi Festival
                </span>
              </span>
            </a>
          </li>
          <li>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <img
                alt="Robot Run 3"
                src="//imga2.5054399.com/upload_pic/2016/11/25/4399_08490640803.jpg"
              />
              <span style={{ verticalAlign: "inherit" }}>
                <span style={{ verticalAlign: "inherit" }}>Robot Run 3</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div className="copy">
        <div className="copy_t">
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}>
              The copyright of the work belongs to the author. If it infringes upon
              your copyright, please{" "}
            </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>contact us</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}>
              {" "}
              and this site will be deleted within 3 working days.{" "}
            </span>
          </span>
          <br />
          <span>
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>Warm reminder:</span>
            </span>
          </span>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}>
              {" "}
              Resist bad games, reject pirated games, pay attention to
              self-protection, beware of being deceived, moderate games are good for
              the brain, addiction to games is harmful to the body, arrange your time
              reasonably, and enjoy a healthy life
            </span>
          </span>
        </div>
        <div className="copy_c">
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>About this site</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>Contact this site</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>Game release</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>4399 open platform</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>4399 public welfare</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>
                Recruitment information
              </span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>Privacy policy</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> |</span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" className="fk">
            <em />
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>Problem feedback</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> |Follow:</span>
          </span>
          {/* <a target={'_blank'} rel="noreferrer" className="wb" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" />
          <a target={'_blank'} rel="noreferrer" className="tx" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk" /> */}
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>Xiamen Chaoyang Mass</span>
            </span>
          </a>
          <br />
          {/* <a target={'_blank'} rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
            className="moc"
          /> */}
          <a target={'_blank'} rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
          >
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>
                Fujian Network Document [2021] No. 4071-076
              </span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>
                (Department) Network Certificate (Min) No. 015
              </span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
          >
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>
                ICP Certificate Fujian B2-20040099
              </span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> |</span>
          </span>
          <a target={'_blank'} rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk"
          >
            <i className="ghs" />
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>
                Fujian Public Network Security No. 35020302000081
              </span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>
                Copyright Protection Complaint Guidelines{" "}
              </span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span style={{ verticalAlign: "inherit" }}>
                | Anti-addiction Statement Online Rumor
              </span>
            </a>
            <span style={{ verticalAlign: "inherit" }}> Exposure </span>
          </span>
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }}>
              <span style={{ verticalAlign: "inherit" }}>Platform</span>
            </span>
          </a>
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}> | </span>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span style={{ verticalAlign: "inherit" }}>
                Reporting and Rumor Refuting Platform
              </span>
            </a>
            <span style={{ verticalAlign: "inherit" }}> | </span>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span style={{ verticalAlign: "inherit" }}>
                Online Harmful Information Reporting Area
              </span>
            </a>
            <span style={{ verticalAlign: "inherit" }}>
              {" "}
              , Minors Reporting Area, Dispute Handling and Inappropriate Content
              Reporting Hotline: 4006834399 (ext. 6) |Report email:
              jubao@4399.com|Parental{" "}
            </span>
            <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
              <span style={{ verticalAlign: "inherit" }}>
                supervision of minorsCopyright
              </span>
            </a>
            <span style={{ verticalAlign: "inherit" }}>
              © 2004 - 2023 4399.com All Rights Reserved. 4399 Network Co., Ltd. All
              rights reserved
            </span>
          </span>
          {/* <br /> */}
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }} />
          </a>
          <span style={{ verticalAlign: "inherit" }} />
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }} />
          </a>
          <span style={{ verticalAlign: "inherit" }} />
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }} />
          </a>
          <span style={{ verticalAlign: "inherit" }} />
          <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftICHM&hl=zh-tw&gl=hk">
            <span style={{ verticalAlign: "inherit" }} />
          </a>
          <br />
          <span style={{ verticalAlign: "inherit" }} />
        </div>
        <div className="wechat-QR">
          <img src="/images/i2016/QR.png" alt='' />
          <span style={{ verticalAlign: "inherit" }} />
          <br />
          <span style={{ verticalAlign: "inherit" }}>
            <span style={{ verticalAlign: "inherit" }}>
              Scan and follow 4399 Mini Games{" "}
            </span>
            <span style={{ verticalAlign: "inherit" }}>on WeChat</span>
          </span>
        </div>
      </div>
    </>
  );
}

export default HomeDemo;