import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
// import Aos from 'aos';
// import 'normalize.css';
import './style/index.scss';
import 'aos/dist/aos.css'

// window.onload = () => {
//   Aos.init({
//     anchorPlacement: 'bottom-center',
//     mirror: true,
//     once: true,
//     // offset: -500,
//   });
// }

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => { };
//   console.error = () => { };
//   console.warn = () => { };
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
