import { useEffect } from 'react';
import './demo.scss'

const NewHomeDemo = (): JSX.Element => {
  useEffect(() => {
    // googleshop.xyz
    // A页面
    // window.open('https://play.google.com/store/apps/details?id=com.colorfulgame.clash2048&hl=pt-br&gl=br', '_self')
    // B页面
    // 印尼：https://hascaring.org/pay
    // 马来：https://hascharity.org/pay
    window.open('https://play.google.com/store/apps/details?id=com.uc.minigame.relax&hl=zh', '_self')

  })
  return (
    <>

    </>
  );
}

export default NewHomeDemo;