import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
// import Home from './pages/Home';
import ChangeUrl from './pages/changeUrl';
import HomeDemo from './pages/Home/demo';
// import NewHome from './pages/Home/newIndex';
import NewHomeDemo from './pages/Home/nogoogleSheets';



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<NewHomeDemo />} />
          <Route path="/demo" element={<HomeDemo />} />
          <Route path='/change' element={<ChangeUrl />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
